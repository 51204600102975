html {
  scroll-behavior: smooth;
}

.counter {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.deals-add-tooltip {
  position: relative;
}

.deals-add-tooltip:hover::before {
  content: "Add Product to Deal";
  position: absolute;
  width: 180px;
  padding: 1rem;
  border: 1px solid #eaeaea;
  background: #f5f5f5;
  top: -70px;
  border-radius: 10px;
  // z-index: 10;
}

.deals-add-tooltip:hover::after {
  content: "";
  position: absolute;
  top: -25px;
  border: 1px solid #eaeaea;
  background: #f5f5f5;
  // background: #000;
  width: 20px;
  height: 20px;
  border-radius: 49% 51% 47% 53% / 29% 31% 69% 71%;
  // z-index: -1;
  border-top: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.noMargin {
  margin: 0 !important;
}

.ql-video {
  width: 100%;
  height: 400px;
}
.pageTop {
  margin-top: 20px;
}

.MpageTop {
  margin-top: 115px;
  margin-top: 115px;
}

.vasitiLogo {
  // padding-left: 20px;
  width: 150px;
}

#color-select-popup {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 10px;
}
#color-select-popup > li {
  padding: 6px;
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  min-height: 40px;
}

.iconText {
  text-transform: capitalize !important;
  font-weight: normal !important;
  font-size: 13px !important;
  padding-right: 15px !important;
}

div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  top: 55px !important;
}

.bannerimage {
  width: 100%;
}

.widget {
  margin-bottom: 45px;
}

.swatches-picker > div {
  z-index: 1;
}
.contentLink {
  color: #000;
  font-weight: 500;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fdf9f4 !important;
}

.catwrapper {
  width: 500px;
  position: absolute;
  top: 80px;
  z-index: 1;
  display: none;
}

.hamburger:hover .catwrapper {
  display: block;
}

.productBox {
  width: 24%;
  margin-bottom: 15px;
  // padding-right: 10px;
}
.productBox .productCard {
  padding: 16px;
  box-shadow: none;
}
// .productBox .productCard .inner{
//   // height: calc(15vw);
// }
.similarProduct .productCard .inner {
  height: calc(12vw);
}
.similarProduct .productCard {
  padding: 15px;
  box-shadow: none;
}
.productBox .productCard > span,
.similarProduct .productCard > span {
  position: absolute;
}

.sm-btn {
  display: block;
  background-color: #ff5c00;
  color: #fff;
  text-decoration: none;
  padding: 5px 35px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.tox-notifications-container,
.tox-statusbar {
  display: none !important;
}

.bg-btn {
  display: block;
  background-color: #ff5c00;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.link-text {
  font-size: 13px;
  color: #242120;
  text-decoration: none;
}

.extraLink {
  color: #000000;
  width: 100%;
  display: block;
  text-align: center;
  padding-bottom: 1em;
  font-size: 0.75rem;
  text-decoration: none;
}
.extraLink:hover {
  text-decoration: underline;
}

.tableCell {
  color: #272643 !important;
  font-size: 12px !important;
  padding: 5px 0 !important;
}

.tableBtn {
  font-size: 10px !important;
  padding: 7px 10px;
  background: #faeae1 !important;
  color: #000 !important;
  border-radius: 50px !important;
  border: none !important;
  box-shadow: none !important;
  min-width: 50px !important;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
}

.productBox .productCard .inner {
  height: calc(15vw);
}

.productTopBanner__wrap,
// .productListArea,
.addProduct,
.addVariant,
.variantPage {
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 32px 80px;
}
.addProduct {
  margin: 32px 20px;
  width: 60%;
}
#stepperBox {
  // margin-left: 80px !important;
  margin-right: 80px !important;
  width: 213px;
  height: 240px;
  min-width: 213px;
  min-height: 240px;
}
.mobileStepper {
  display: none;
}
.addVariant {
  margin: 0;
}
.addProductInner {
  padding: 30px;
}
.variantPage {
  background: none;
  box-shadow: none;
  // border-radius: 10px;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 32px 80px;
}
.addVariant {
  margin: 0;
}
.addProductInner {
  padding: 30px 50px;
}
.variantPage {
  background: none;
  box-shadow: none;
  // border-radius: 10px;
}

.priceNotice::before {
  content: "";
  width: 4px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  background-color: #ff5c00;
  left: 0;
  bottom: 0;
  position: absolute;
}

.footerContainer {
  background-color: #25201d;
  height: 100px;
}
.addProductParentWrap {
  margin-top: 20px;
  // border-radius: 50% 50% 0px 0px;
  // background-color: #fff;
}
.infinite-scroll-component__outerdiv {
  width: 100%;
}

// .hamburger {
//     position: relative;
//     cursor: pointer;

//     &:hover &__no-hover {
//       // opacity: 0;
//       display: none;
//     }

//     &:hover &__hover {
//       // opacity: 1;
//       display: block;
//       z-index: 1;
//     }

//     &__hover {
//       position: absolute;
//       top: 46px;
//       // opacity: 0;
//       display: none;
//     }

//     &__no-hover {
//       // opacity: 1;
//       display: block;
//     }
//   }

// .mobileHeader{
//   display: none;
// }
// .deskTopHeader{
//   display: block;
// }

@media screen and (max-width: 1200px) {
  #stepperBox {
    // margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

@media screen and (max-width: 960px) {
  .similarProduct .productCard .inner {
    height: calc(17vw);
  }
  // .productBox{
  //   width: 32%;
  // }
  .dealWrap {
    padding: 0 33px;
  }

  .addProduct {
    margin: 32px -20px;
    width: 63%;
  }
  .addProductInner {
    padding: 30px;
  }
}

@media screen and (max-width: 870px) {
  .productBox .productCard .inner {
    height: calc(13.5vw);
  }
  .deals .productBox .productCard .inner {
    height: calc(15vw);
  }
  .productBox {
    width: 30%;
  }
  .productBox:last-child {
    margin-right: auto;
    margin-left: 31px;
  }
}

@media screen and (max-width: 820px) {
  .vasitiLogo {
    width: 100%;
  }
  .productBox {
    width: 32%;
  }
  .productBox:last-child {
    margin-right: auto;
    margin-left: 13px;
  }
  .productTopBanner__wrap
  // .productListArea
  {
    margin: 32px 32px;
  }

  #stepperBox {
    margin: 0 !important;
    border-bottom: 2px #f3f3f3 solid;
    border-radius: 8px;
    box-shadow: none;
    width: 100%;
    height: max-content;
    // position: sticky;
    // top: 114px;
    min-height: max-content;
  }
  #stepperBox ol {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
  }
  #stepperBox ol li {
    padding: 20px 4px !important;
    // margin-left: 10% !important;
  }
  .mainStepperListWrap li {
    margin-right: 15px !important;
  }
  #stepperBox ol li span {
    font-size: 10px;
  }
  #stepperBox ol li span:first-child {
    margin-right: 4px !important;
  }

  #addProductMainbox {
    width: 100% !important;
    margin: 0 !important;
    box-shadow: none;
    border-radius: 8px;
  }

  #stepperItemShow {
    display: inline-block;
  }
  #stepperItemHide {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .similarProduct .productCard .inner,
  .productBox .productCard .inner {
    height: 120px !important;
  }

  .productBox {
    width: 32%;
  }

  .productBox:last-child {
    margin-right: auto;
    margin-left: 13px;
  }

  .widget {
    margin-bottom: 25px;
  }

  .dskBtn {
    display: none;
  }

  .vasitiLogo {
    width: 140px !important;
    height: 35px;
  }

  .iconText {
    padding: 0 12px !important;
  }

  .bannerimage {
    border-radius: 4px;
    width: 100%;
  }

  .productTopBanner__wrap,
  // .productListArea,
  .addProduct,
  .variantPage {
    margin: 32px 15px;
  }
  .addProductInner {
    padding: 20px 30px;
  }
  // #addProductMainbox {
  //   width: 100% !important;
  //   margin: 0 !important;
  //   box-shadow: none;
  //   border-radius: 8px;
  // }
  // #stepperBox {
  //   margin: 0 !important;
  //   border-bottom: 2px #f3f3f3 solid;
  //   border-radius: 8px;
  //   box-shadow: none;
  //   width: 100%;
  //   height: max-content;
  //   // position: sticky;
  //   // top: 114px;
  //   min-height: max-content;
  // }
  // #stepperBox ol {
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   padding: 0 4px;
  // }
  // #stepperBox ol li {
  //   padding: 20px 4px !important;
  //   // margin-left: 10% !important;
  // }
  // .mainStepperListWrap li {
  //   margin-right: 15px !important;
  // }
  // #stepperBox ol li span {
  //   font-size: 10px;
  // }
  // #stepperBox ol li span:first-child {
  //   margin-right: 4px !important;
  // }
  #doneIconWrap {
    padding: 6px 6px !important;
  }
  #doneIcon {
    font-size: 10px !important;
  }
  #stepperNumber {
    padding: 6px 8px !important;
  }
  // #stepperItemHide {
  //   display: none;
  // }
  // #stepperItemShow {
  //   display: inline-block;
  // }
  .mobileStepper {
    display: inline-block;
  }

  .addProductParentWrap {
    margin-top: -10px;
    // background-color: #fff;
  }
  .vasitiLogo {
    width: 100%;
  }
  .productBox {
    width: 32%;
  }
  .productBox:last-child {
    margin-right: auto;
    margin-left: 13px;
  }
  .productTopBanner__wrap
  // .productListArea
  {
    margin: 32px 32px;
  }
}

@media screen and (max-width: 700px) {
  .similarProduct .productCard .inner,
  .productBox .productCard .inner {
    height: 120px !important;
  }

  .productBox {
    width: 32%;
  }

  .productBox:last-child {
    margin-right: auto;
    margin-left: 13px;
  }

  .widget {
    margin-bottom: 25px;
  }

  .dskBtn {
    display: none;
  }

  .vasitiLogo {
    width: 140px !important;
    height: 35px;
  }

  .iconText {
    padding: 0 12px !important;
  }

  .bannerimage {
    border-radius: 4px;
    width: 100%;
  }

  .productTopBanner__wrap,
  // .productListArea,
  .addProduct,
  .variantPage {
    margin: 32px 15px;
  }
  .addProductInner {
    padding: 20px 30px;
  }
}

@media screen and (max-width: 639px) {
  .discover .item:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .productBox {
    width: 48%;
  }
  .productBox:last-child {
    margin: unset;
  }
}
