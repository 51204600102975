@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .v-input {
    @apply w-full p-2 text-sm font-semibold rounded bg-fa hover:border-primary hover:border-opacity-60 border-opacity-25 border border-gray-300 focus:border-primary focus:outline-none;
  }
  .btn-sm-primary {
    @apply py-2 px-4 bg-primary text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-75;
  }
  /* .btn-sm-primary {
    @apply py-2 px-4 bg-primary text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-75;
  }
  .btn-primary {
    @apply py-1.5 px-2 bg-primary text-white font-medium rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-75;
  }
  .btn-round-dark {
    @apply bg-offBlack text-white font-medium text-1xss p-2 rounded-3xl;
  }
  .btn-round-light {
    @apply bg-vasitiGray text-gray-400 border-gray-300 border font-medium text-1xss p-2 rounded-3xl;
  } */
}

.dropdown:hover .dropdown-menu,
.dropdown:focus .dropdown-menu {
  display: inline-block;
}
